export async function translateString(string, from = "auto", to = "en") {
    try {
        const res = await fetch("https://lokota.travelafrique.com/translate", {
            method: "POST",
            body: JSON.stringify({
                q: string,
                source: from,
                target: to,
                format: "text",
                api_key: ""
            }),
            headers: {
                "Content-Type": "application/json"
            }
        });
        const data = await res.json();
        return data;
    } catch (error) {
        console.log(error);
        return error;
    }
}

