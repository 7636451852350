<template>
  <section class="section" id="cancellation">
    <div class="wrapper">
      <h2 class="section-title">{{ $t("offer.cancellation.title") }}</h2>
      <div class="grid gap-4 mt-6 refund-cards s:grid-cols-2">
        <div class="refund-card">
          <div class="w-2 h-full color-label refund-full"></div>
          <div class="p-3 text">
            <h5 class="font-sans font-medium text-base-text">
              {{ $t("offer.cancellation.full") }} · 100%
            </h5>
            <p class="font-sans text-sm text-base-text">
              {{ fullText }}
            </p>
          </div>
        </div>
        <div class="refund-card">
          <div class="w-2 h-full color-label bg-yellow-base"></div>
          <div class="p-3 text">
            <h5 class="font-sans font-medium text-base-text">
              {{ $t("offer.cancellation.partial") }} · {{ partial_refund }}%
            </h5>
            <p class="font-sans text-sm text-base-text">
              {{ partialText }}
            </p>
          </div>
        </div>
        <div class="refund-card">
          <div class="w-2 h-full color-label bg-red-base"></div>
          <div class="p-3 text">
            <h5 class="font-sans font-medium text-base-text">
              {{ $t("offer.cancellation.no") }} · 0%
            </h5>
            <p class="font-sans text-sm text-base-text">
              {{ noText }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
export default {
  name: "CancellationBlock",
  props: {
    partial_refund: {
      type: Number,
      required: true,
      default: 0,
    },
    partial_refund_days: {
      type: Number,
      required: true,
      default: 0,
    },
    full_refund_days: {
      type: Number,
      required: true,
      default: 0,
    },
    no_refund_days: {
      type: Number,
      required: true,
      default: 0,
    },
  },
  computed: {
    fullText() {
      let count = this.full_refund_days;
      return this.$tc("offer.cancellation.text", count, { count: count });
    },
    partialText() {
      let count = this.partial_refund_days;
      return this.$tc("offer.cancellation.text", count, { count: count });
    },
    noText() {
      let count = this.no_refund_days;
      return this.$tc("offer.cancellation.text", count, { count: count });
    },
  },
};
</script>
<style scoped>
.section-title {
  @apply font-sans text-2xl font-semibold text-black-base;
}

.refund-full {
  @apply bg-green-base;
}

.refund-card {
  @apply rounded-md  overflow-hidden border border-grey-base shadow-sm flex;
}

.section {
  @apply px-4;
}
.wrapper {
  @apply relative transform -translate-x-1/2 max-w-screen-sm w-full left-1/2;
}
</style>
