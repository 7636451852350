<template>
	<div class="scroll-smooth">
		<GalleryLightbox @closeLightbox="closeLightbox" @closeGallery="closeLightbox" v-if="showGalleryLightbox"
			:photos="photos" class="z-30 fixed top-0 left-0" />
		<Navbar mode="solid" class="navbar" :show-btn-icon="true" :show-avatar="false" :show-btns="false"
			btn-link-path="/tours" />
		<main class="main">
			<section v-if="Object.keys(tour).length < 1 && !loading" class="empty-state section">
				<div class="wrapper">
					<EmptyState :btnText="$t('common.cta.go_back_to_tours')"
						:text="$t('empty_state.something_went_wrong.text')"
						:title="$t('empty_state.something_went_wrong.title')" :use-link="true" icon-color="red" class="mt-8"
						icon-class="bx bx-error" link-path="/tours" />
				</div>
			</section>

			<section class="section" v-if="loading">
				<div class="wrapper grid gap-8">
					<SkeletonTripRequest> </SkeletonTripRequest>
					<SkeletonTripRequest> </SkeletonTripRequest>
					<SkeletonTripRequest> </SkeletonTripRequest>
					<SkeletonTripRequest> </SkeletonTripRequest>
					<SkeletonTripRequest> </SkeletonTripRequest>
					<SkeletonTripRequest> </SkeletonTripRequest>
				</div>
			</section>
			<div v-if="!loading && Object.keys(tour).length > 0" class="offer">
				<section id="gallery">
					<div class="wrapper">
						<Gallery :mainPhoto="photosInItinerary[0] || placeholder"
							:showCalltoAction="photosInItinerary.length !== 0"
							:sidePhotoTop="photosInItinerary[1] || placeholder"
							:sidePhotoBottom="photosInItinerary[2] || placeholder"
							@click.native="openLightBox(photosInItinerary)" />
					</div>
				</section>
				<section class="section">
					<div class="wrapper">
						<div class="translation-disclaimer">
							<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256">
								<path
									d="M160,129.89,175.06,160H144.94l6.36-12.7v0ZM224,48V208a16,16,0,0,1-16,16H48a16,16,0,0,1-16-16V48A16,16,0,0,1,48,32H208A16,16,0,0,1,224,48ZM207.16,188.42l-40-80a8,8,0,0,0-14.32,0L139.66,134.8a62.31,62.31,0,0,1-23.61-10A79.61,79.61,0,0,0,135.6,80H152a8,8,0,0,0,0-16H112V56a8,8,0,0,0-16,0v8H56a8,8,0,0,0,0,16h63.48a63.73,63.73,0,0,1-15.3,34.05,65.93,65.93,0,0,1-9-13.61,8,8,0,0,0-14.32,7.12,81.75,81.75,0,0,0,11.4,17.15A63.62,63.62,0,0,1,56,136a8,8,0,0,0,0,16,79.56,79.56,0,0,0,48.11-16.13,78.33,78.33,0,0,0,28.18,13.66l-19.45,38.89a8,8,0,0,0,14.32,7.16L136.94,176h46.12l9.78,19.58a8,8,0,1,0,14.32-7.16Z">
								</path>
							</svg>
							<p>{{ $t("common.translation_disclaimer") }}</p>
						</div>
						<h1 class="font-semibold text-2xl text-black-base">
							{{ tour.title }}
						</h1>
						<div class="section-tags pb-3">
							<div class="destination">
								{{ tour.destination }} • {{ $tc("common.days", tour.duration) }}
							</div>
						</div>
						<div>

						</div>
						<Banner type="info" :title="$t('page.group_tour.banner.title')"
							:description="$t('page.group_tour.banner.description')" class="w-full" />
					</div>
				</section>

				<AgencyBlock v-if="!loading" :photo="tour.partner.profile_photo"
					:agencyName="tour.partner.first_name | agencyGenitive()" :rating="rating" :reviews="reviews.length"
					:loading="loading" />
				<section class="section">
					<div class="wrapper">
						<div class="info--tile--container">
							<div class="info--tile">
								<span class="info--tile--title">
									{{$t("page.group_tour.details.operated_in")}}
								</span>
								<span class="info--tile--value">
									{{ langs[lang][tour.language] }}
								</span>
							</div>
							<div class="info--tile">
								<span class="info--tile--title">
									{{$t("page.group_tour.details.max_group_size")}}
								</span>
								<span class="info--tile--value">
									{{ tour.max_group_size }}
								</span>
							</div>
							<div class="info--tile">
								<span class="info--tile--title">
									{{$t("page.group_tour.details.min_age_to_travel")}}
								</span>
								<span class="info--tile--value">
									{{ $tc("common.year", tour.min_age) }}
								</span>
							</div>
							<div class="info--tile">
								<span class="info--tile--title">
									{{ $t("page.group_tour.details.group_tour_id") }}
								</span>
								<span class="info--tile--value">
									{{ tour.id }}
								</span>
							</div>
						</div>
					</div>
				</section>
				<section class="section">
					<div class="wrapper">
						<h2 class="section-title" v-t="'page.group_tour.section.dates.title'" />
						<div class="dates-container" v-if="showFirstDate">
							<DateItem @click="startBooking(tour.dates[0].id, tour.dates[0].tour_date)"
								:date="tour.dates[0].tour_date" :group-size="tour.dates[0].places_left" />
							<DateItem v-if="showSecondDate"
								@click=" startBooking(tour.dates[1].id, tour.dates[1].tour_date)"
								:date="tour.dates[1].tour_date" :group-size="tour.dates[1].places_left" />
							<Button v-if="showAllDatesBtn" text="Show all dates" @click.native="togglePriceModal()"
								iconClass="bx bxs-right-top-arrow-circle" icon-position="right">
							</Button>
						</div>
						<div class="dates-container" v-else>
							<EmptyState icon-class="bx bxs-calendar-star"
								:title="$t('page.group_tour.section.dates.empty_state.title')"
								:text="$t('page.group_tour.section.dates.empty_state.title')" />
						</div>
					</div>
				</section>
				<PricingBlock :single-room-price="tour.single_room_price" :shared-room-price="tour.shared_room_price"
					:tour-currency="tour.currency" class="px-4" />

				<section class="section">
					<div class="wrapper">
						<div class="inclusions items-section mb-6">
							<h3 class="font-sans text-lg font-semibold text-black-base">
								{{ $t("offer.scope.inclusions") }}
							</h3>
							<div class="mt-2 list">
								<div v-for="(inclusion, index) in inclusions" :key="index" class="flex items-start mb-3">
									<i class="text-lg bx bxs-check-circle inclusion-icon mt-0.5"></i>
									<div class="ml-2 font-sans text-black-base">
										{{ inclusion }}
									</div>
								</div>
							</div>
						</div>
						<div class="exclusions items-section">
							<h3 class="font-sans text-lg font-semibold text-black-base">
								{{ $t("offer.scope.exclusions") }}
							</h3>
							<div class="mt-2 list">
								<div v-for="(exclusion, index) in exclusions" :key="index" class="flex items-start mb-3">
									<i class="text-lg bx bxs-x-circle exclusion-icon mt-0.5"></i>
									<div class="ml-2 font-sans text-black-base">
										{{ exclusion }}
									</div>
								</div>
							</div>
						</div>
					</div>
				</section>
				<section id="itinerary" class="section" v-if="itinerary">
					<div class="wrapper">
						<h2 class="section-title mb-6">
							{{ $t("offer.itinerary.title") }}
						</h2>
						<DayBlock v-for="(itinerary, index) in itinerary" :key="index" :itinerary="itinerary"
							:title="`${$t('offer.itinerary.day')} ${index + 1}: ${itinerary.title}`"
							@showPhotos="showAccommodationPhotos" />
					</div>
				</section>
				<CancellationBlock :no_refund_days="tour.no_refund_days" :full_refund_days="tour.full_refund_days"
					:partial_refund_days="tour.partial_refund_days" :partial_refund="tour.partial_refund" />
				<ReviewBlock v-if="!loading" :rating="rating" :reviews="reviews" :loading="loading" />
			</div>
		</main>
		<div class="bottom-bar" v-if="showBottomBar">
			<div class="bottom-bar-container">
				<div class="bottom-bar-total-price">
					<div class="bottom-bar-label" v-t="'common.price_from'" />
					<div class="bottom-bar-price">
						{{tour.shared_room_price | currency(tour.currency, getCurrency,
												rates[`${tour.currency}_${getCurrency}`])}}
					</div>
				</div>
				<Button :text="$t('common.cta.reserve_a_date')" kind="primary" size="md" :show-icon="false"
					@click.native="goToDates()" />
			</div>
		</div>
		<div class="dates-modal" v-if="showPriceModal">
			<div class="dates-modal-container">
				<div class="dates-modal-wrapper">
					<Button icon-class="bx bx-x" shape="round" class="close-btn" :show-text="false" size="lg"
						@click.native="togglePriceModal()" />
					<PricingBlock :single-room-price="tour.single_room_price" :shared-room-price="tour.shared_room_price"
						:tour-currency="tour.currency" class="mb-8" />
					<h2 class="section-title mb-6">Tour dates</h2>
					<div v-if="tour.dates.length > 0">
						<div v-for="date in tour.dates" :key="date.id">
							<DateItem v-if="date.places_left" :date="date.tour_date" :group-size="date.places_left"
								@click="startBooking(date.id, date.tour_date)" class="mb-4" />
						</div>
					</div>
				</div>
			</div>
		</div>
		<BackgroundLayer class="bg-layer" @click.native="togglePriceModal()" v-if="showPriceModal" />
	</div>
</template>

<script>
import EmptyState from "@/components/EmptyState";
import Gallery from "@/components/gallery/Gallery";
import DayBlock from "./blocks/DayBlock.vue";
import PricingBlock from "./blocks/PricingBlock.vue";
import CancellationBlock from "./blocks/CancellationBlock";
import { mapGetters, mapActions } from "vuex";
import ReviewBlock from "./blocks/ReviewBlock.vue";
import AgencyBlock from "./blocks/AgencyBlock.vue";
import api from "@/api";
import DateItem from "../../components/DateItem.vue";
import Button from "@/components/buttons/Button.vue";
import BackgroundLayer from "@/components/BackgroundLayer.vue";
import SkeletonTripRequest from "@/components/skeletons/SkeletonTripRequest.vue";
import GalleryLightbox from "@/components/gallery/GalleryLightbox.vue";
import Banner from "@/components/banners/Banner.vue";
import { translateString } from "../../../../plugins/translation";
import { checkIfDatesAreEqual, addDays, getNumNightsFromString } from "@/shared/dateHandler.js";
export default {
	name: "Tour",
	components: {
		CancellationBlock,
		DayBlock,
		EmptyState,
		AgencyBlock,
		Gallery,
		PricingBlock,
		ReviewBlock,
		DateItem,
		Button,
		BackgroundLayer,
		SkeletonTripRequest,
		GalleryLightbox,
		Banner,
	},
	data() {
		return {
			placeholder: {
				img: require("@/assets/img/placeholders/placeholder.png"),
			},
			region: "en-CH",
			acc: "",
			loading: true,
			reviews: [],
			langs: {
				en: { fr: "French", en: "English" },
				fr: { fr: "Français", en: "Anglais" },
			},
			lang: localStorage.getItem("lang"),
			rating: undefined,
			showPriceModal: false,
			showGalleryLightbox: false,
			tour: { dates: [], places_left: 0 },
			itinerary: undefined,
			photosInItinerary: undefined,
			exchangeRates: {},
			inclusions: [],
			exclusions: [],
		};
	},
	computed: {
		...mapGetters({
			getCurrency: "global/currency",
			rates: "exchange/fixedRates",
			initialRates: "exchange/rates",
		}),
		showFirstDate() {
			if (this.tour.dates.length > 0) {
				if (this.tour.dates[0]) {
					return true;
				}
			}
			return false;
		},
		showSecondDate() {
			if (this.tour.dates.length > 0) {
				if (this.tour.dates[1]) {
					return true;
				}
			}
			return false;
		},
		showAllDatesBtn() {
			return this.tour.dates.length > 2;
		},
		showBottomBar() {
			return this.tour.dates.length > 0;
		},
		tourId() {
			return this.$route.query.id;
		},
		app_file_base_path() {
			return process.env.VUE_APP_FILE_BASE_PATH;
		},
		accommodations() {
			return JSON.parse(this.tour.accommodation);
		},
		status() {
			let trip_request_id = this.tour.trip_request_id;
			let status;
			this.pendingTrips.forEach((request) => {
				if (request.id === trip_request_id)
					status = request.trip_request_status;
			});

			return (
				status === "open" || status === "changes" || status === "draft"
			);
		},
	},
	methods: {
		...mapActions("user", ["getSingleTripRequest", "getTripAgent"]),
		...mapActions({
			resetState: "tour/resetState",
			getTour: "tour/getTour",
			setDates: "tour/setDates",
			setCurrencyRates: "exchange/setCurrencyRates",
			setFixedCurrencyRates: "exchange/setFixedCurrencyRates",
		}),
		getPhotos() {
			let itinerary = this.tour.itinerary;
			const itineraries = JSON.parse(itinerary).map(
				(itinerary) => itinerary.experiences
			);

			const photos = itineraries.map((itinerary) =>
				itinerary.map((photo) => photo.photo.split(","))
			);
			const soo = photos.flat(2);

			let a = [];
			soo.forEach((e) => {
				if (e) {
					let array = e.split("/"); // Split image path and put splits into array
					a.push({
						img: `${this.app_file_base_path}/${e}`,
						alt: this.replace(array[4]), //Check if e is available and access 5th array element
					});
				}
			});
			return a;
		},
		togglePriceModal() {
			const body = document.querySelector("body");
			this.showPriceModal = !this.showPriceModal;
			if (this.showPriceModal === true) {
				body.classList.add("overflow-hidden");
			} else {
				body.classList.remove("overflow-hidden");
			}
		},
		closeLightbox() {
			this.showGalleryLightbox = !this.showGalleryLightbox;
			document.body.style.overflow = "auto";
		},
		openLightBox(object) {
			this.showGalleryLightbox = true;
			this.photos = [...object];
			document.body.style.overflow = "hidden";
		},
		getRating(reviews) {
			let amountOfReviews = reviews.length;
			let sum = reviews.reduce(
				(accumulator, review) => accumulator + parseInt(review.rating),
				0
			);
			let average = sum / amountOfReviews;
			average = Math.round(average * 10) / 10;
			return parseFloat(average);
		},
		sortByDateAsc(a, b) {
			a = new Date(a.updated_at);
			b = new Date(b.updated_at);
			return a > b ? -1 : a < b ? 1 : 0;
		},
		showAccommodationPhotos(paths) {
			let arr = [];
			for (let route of paths) {
				let path_split = route.split("/");
				arr.push({
					img: `${this.app_file_base_path}/${route}`,
					alt: path_split[4],
				});
			}
			this.openLightBox(arr);
		},
		replace(str) {
			return str.replace(/-/g, " ");
		},
		goToDates() {
			this.$router.push(`/book/dates?id=${this.tourId}&origin=dates`);
		},
		startBooking(dateId, tripDate) {
			this.setDates(tripDate);
			if (this.isAuthenticated === true) {
				this.$router.push(
				`/book/details?id=${this.tourId}&date_id=${dateId}&trip_date=${tripDate}&origin=tour`
				);
			} else {
				this.$router.push(
				`/book/login?id=${this.tourId}&date_id=${dateId}&trip_date=${tripDate}&origin=tour`
				);
			}
			
		},
		/*CODE FOR ACCOMMODATIONS*/
		getIsoDate(date) {
			return new Date(date).toISOString();
		},
		//✅ Gets amount of nights a traveller stays at an accommodation
		getAmountOfNights(accommodation) {
			let fullDate = accommodation.fullCheckinDate + "|" + accommodation.fullCheckoutDate;
			return getNumNightsFromString(fullDate);
		},
		getSplittedAccommodations(accommodations) {
			let splittedAccommodations = [];
			accommodations.forEach((accommodation) => {
				let nightsOfStay = this.getAmountOfNights(accommodation);
				for (let i = 0; i < nightsOfStay; i++) {
					splittedAccommodations.push({
						fullDate: addDays(accommodation.fullCheckinDate,i).toISOString(),
						address: accommodation.address,
						description: accommodation.description,
						images: accommodation.image.split(","),
						mealplan: accommodation.mealplan,
						name: accommodation.name,
					});
				}
			});
			return splittedAccommodations;
		},
		setAccommodationsInItinerary() {
			let accommodations = JSON.parse(this.tour.accommodation);
			let itineraries = JSON.parse(this.tour.itinerary);
			accommodations = this.getSplittedAccommodations(accommodations);

			for (let i = 0; i < itineraries.length; i++) {
				let itinerary = itineraries[i];
				itinerary.accommodation = {};
				for (let j = 0; j < accommodations.length; j++) {
					let accommodation = accommodations[j];
					if (checkIfDatesAreEqual(itinerary.fullDate, accommodation.fullDate)) {
						itinerary.accommodation = accommodation;
						break;
					}
				}
			}
			return itineraries;
		},
	},
	async beforeMount() { },
	async created() {
		this.resetState();

		if (Object.keys(this.rates).length < 1) {
			await this.setCurrencyRates();
			this.setFixedCurrencyRates(this.initialRates);
		}

		this.tour = await this.getTour(this.tourId)
			.then((res) => res.data.tour)
			.catch((err) => {
				console.log(err);
			});

		//Translate title
		await translateString(await this.tour.title, "fr", this.lang).then(
			async (res) => {
				this.tour.title = await res.translatedText;
			}
		);

		//Translate exclusions and inclusions
		let exclusions = await this.tour.exclusions.split(",");
		let exclusions_translated = [];
		exclusions.forEach(async (exclusion) => {
			await translateString(exclusion, "fr", this.lang).then(
				async (res) =>
					exclusions_translated.push(await res.translatedText)
			);
		});

		let inclusions = await this.tour.inclusions.split(",");
		let inclusions_translated = [];
		inclusions.forEach(async (inclusion) => {
			await translateString(inclusion, "fr", this.lang).then(
				async (res) =>
					inclusions_translated.push(await res.translatedText)
			);
		});

		this.exclusions = exclusions_translated;
		this.inclusions = inclusions_translated;
		this.photosInItinerary = this.getPhotos();
		this.itinerary = this.setAccommodationsInItinerary();
		this.reviews = [
			...(await api
				.getPartnerReviews(this.tour.agency)
				.then((res) => {
					if (res.data.reviews !== undefined) {
						this.rating = this.getRating(res.data.reviews);
						return res.data.reviews.sort(this.sortByDateAsc);
					} else {
						return [];
					}
				})
				.catch((err) => console.log(err))),
		];
		
		this.loading = false;
	},
};
</script>

<style scoped>
.bottom-bar {
	@apply fixed z-10 bottom-0 px-4 py-2 mt-4;
	@apply bg-white border border-grey-base shadow-sm;
	@apply w-full;
}

.bottom-bar-container {
	@apply max-w-screen-sm w-full;
	@apply relative left-1/2 transform -translate-x-1/2;
	@apply flex items-center justify-between;
}

.bottom-bar-price {
	@apply font-bold text-black-base;
}

.bottom-bar-label {
	@apply text-black-lighter text-sm;
}

.info--tile--container {
	@apply flex gap-4 flex-wrap;
}

.info--tile {
	@apply p-3 rounded-lg bg-grey-base bg-opacity-50;
	width: calc((100% - 1rem) / 2);
}

.inclusion-icon {
	@apply text-green-base mt-0.5;
}

.exclusion-icon {
	@apply text-red-base mt-0.5;
}

.navbar {
	@apply fixed left-0 top-0 w-full z-40 border-none;
}

.main {
	@apply py-20 sm:py-32;
}

.section-tags {
	@apply flex mt-1 text-lg text-black-light mb-4;
}

.section {
	@apply px-4 py-6;
}

.section-container {
	@apply px-4 sm:px-0;
}

.section-title {
	@apply font-sans font-semibold text-black-base text-2xl;
}

.wrapper {
	@apply relative transform -translate-x-1/2 max-w-screen-sm left-1/2;
}

.dates-container {
	@apply grid grid-cols-1 gap-4 pt-4;
}

.dates-modal {
	@apply fixed left-1/2 transform -translate-x-1/2 top-1/2 -translate-y-1/2;
	@apply z-50 h-full md:h-90vh overflow-hidden md:rounded-xl;
	@apply bg-white md:max-w-screen-md w-full;
}

.dates-modal-container {
	@apply overflow-y-auto h-full relative w-full px-4;
}

.dates-modal-wrapper {
	@apply max-w-2xl left-1/2 transform -translate-x-1/2 relative;
	@apply py-8;
}

.date-items-container {
	@apply gap-y-4 grid;
}

.bg-layer {
	@apply z-40;
}

.close-btn {
	@apply ml-auto flex;
}

.info--tile--title {
	@apply font-sans text-base font-semibold text-black-base block;
}

.info--tile--value {
	@apply text-sm text-black-light block;
}
.translation-disclaimer {
	@apply flex gap-2 items-center mb-6;
}
.translation-disclaimer svg{
	@apply text-black-base self-start min-h-5 w-5 h-5 min-w-5;
}

.translation-disclaimer p{
	@apply text-black-base text-sm;
}
</style>
