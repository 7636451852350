<template>
  <section class="section" id="reviews">
    <div class="wrapper">
      <h2 class="title">{{ $t("page.my_trips.offer.reviews.title") }}</h2>
      <div v-if="reviews.length > 0">
        <RatingSummary :rating="rating" :reviews="reviews.length" />
        <div class="mt-8 grid grid-flow-row gap-3" v-if="!loading">
          <ReviewBox
            v-for="(review, index) in reviews"
            :key="index"
            :img="
              review.image !== ''
                ? `${app_file_base_path}${review.image}`
                : review.image
            "
            :rating="parseInt(review.rating)"
            :user-name="review.name"
            :date="review.updated_at"
            :destination="review.destination"
            :feedback="review.review"
            :trim-feedback="true"
            :show-expander="true"
            :trimm-length="240"
          />
        </div>
      </div>
      <EmptyState
        v-else
        icon-class="bx bxs-star"
        :title="$t('empty_state.no_reviews_yet.title')"
        :text="$t('empty_state.no_reviews_yet.text')"
        :use-call-to-action="false"
      />
    </div>
  </section>
</template>

<script>
import ReviewBox from "@/components/review/ReviewBox";
import RatingSummary from "@/components/review/RatingSummary.vue";
import EmptyState from "@/components/EmptyState.vue";
export default {
  name: "ReviewBlock",
  components: { ReviewBox, RatingSummary, EmptyState },
  props: {
    reviews: {
      type: Array,
      default: () => [],
    },
    rating: {
      type: Number,
      default: 0,
    },
    loading: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    app_file_base_path() {
      return process.env.VUE_APP_FILE_BASE_PATH;
    },
  },
};
</script>

<style scoped>
.title {
  @apply font-sans font-semibold text-black-base text-2xl mb-8;
}

.section {
  @apply px-4 py-6;
}
.wrapper {
  @apply relative transform -translate-x-1/2 max-w-screen-sm left-1/2;
}
</style>