<template>
    <div role="status" class="skeleton">
        <div class="skeleton-head-row">
            <div class="skeleton-calendar-icon"></div>
            <div class="skeleton-title"></div>
        </div>
        <div class="skeleton-meal-plan-container">
            <div class="skeleton-meal-plan"></div>
            <div class="skeleton-meal-plan"></div>
        </div>
        <div class="skeleton-meal-plan-container mt-2">
            <div class="skeleton-meal-plan"></div>
        </div>
        <div class="skeleton-details-container">
            <div class="skeleton-details md"></div>
            <div class="skeleton-details sm"></div>
            <div class="skeleton-details lg"></div>
            <div class="skeleton-details lg"></div>
        </div>
        <div class="skeleton-btn"></div>
    </div>
</template>
<script>
export default {
    name: "SkeletonDayBlock"
}
</script>
<style scoped>
.skeleton {
    @apply animate-pulse flex flex-col gap-y-4 flex-wrap;
    @apply py-8 w-full;
    @apply inline-block bg-white;
}

.skeleton-head-row {
    @apply flex gap-3 items-center w-full  mb-6;
}

.skeleton-calendar-icon {
    @apply bg-grey-base h-2 rounded-full;
    @apply h-6 w-6;
}

.skeleton-title {
    @apply h-7 w-44 bg-grey-base rounded-md;
}

.skeleton-meal-plan-container{
    @apply flex gap-1.5;
}

.skeleton-meal-plan{
    @apply h-7 w-32 bg-grey-base;
    @apply rounded-md;
}

.skeleton-details-container {
    @apply flex flex-col gap-4 mt-6;
}

.skeleton-details {
    @apply h-2 bg-grey-base rounded-full;
    @apply w-52;
}

.skeleton-btn {
    @apply h-10 w-40 bg-grey-base rounded-md mt-6;
}

.sm {
    @apply w-12;
}


.md {
    @apply w-24;
}

.lg {
    @apply w-full;
}
</style>