<template>
	<section class="pricing-section" id="pricing">
		<div class="wrapper">
			<h2 class="section-title" v-t="'page.group_tour.section.price.title'"/>
			<div class="price--items--container">
				<div class="price--item">
					<div class="price--text--container" property="description">
						<h3 class="price--title">
							<i class="bx bxs-user bx-xs"> </i> {{ $t("page.group_tour.section.price.single_room") }}
						</h3>
						<p class="price--description" v-t="'page.group_tour.section.price.single_room.text'" />
					</div>
					<div class="price--container" property="offers" typeof="Offer">
						<span class="price"> {{ singleRoomPrice | currency(tourCurrency, getCurrency, rates[`${tourCurrency}_${getCurrency}`]) }} </span>
						<span class="price--unit" v-t="'common.per_traveller'"/>
					</div>
				</div>
				<div class="price--item">
					<div class="price--text--container" property="description">
						<h3 class="price--title">
							<i class="bx bxs-group"> </i> {{ $t("page.group_tour.section.price.shared_room") }}
						</h3>
						<p class="price--description" v-t="'page.group_tour.section.price.shared_room.text'"/>
					</div>
					<div class="price--container" property="offers" typeof="Offer">
						<span class="price">
							{{ sharedRoomPrice | currency(tourCurrency, getCurrency, rates[`${tourCurrency}_${getCurrency}`]) }}
						</span>
						<span class="price--unit" v-t="'common.per_traveller'"/>
					</div>
				</div>
			</div>
		</div>
	</section>
</template>

<script>
import { mapGetters } from "vuex";
export default {
	name: "PricingBlock",
	data() {
		return {
			exchangeRates: {},
		};
	},
	props: {
		singleRoomPrice: {
			type:Number,
		},
		sharedRoomPrice: {
			type:Number,
		},
		tourCurrency: {
			type:String,
		}
	},
	computed: {
		...mapGetters({
			getCurrency: "global/currency",
			rates:"exchange/rates"
		}),
	},
};
</script>

<style scoped>
.pricing-section {
	@apply py-6;
}

.section-title {
	@apply font-sans text-2xl font-semibold text-black-base;
}

.wrapper {
	@apply relative transform -translate-x-1/2 max-w-screen-sm left-1/2;
}

.price--items--container {
	@apply grid gap-4 mt-6;
}

.price--item {
	@apply border border-grey-base rounded-lg p-4 shadow-sm;
	@apply flex items-start justify-between gap-8;
}

.price--title {
	@apply font-semibold text-black-base text-lg;
	@apply flex items-center gap-1;
}

.price--description {
	@apply text-xs text-black-lighter;
}

.price--container > span {
	@apply block;
}

.price {
	@apply font-bold text-base text-black-base text-right;
}

.price--unit {
	@apply text-black-lighter text-xs font-medium text-right;
}
</style>
