<template>
	<section class="section" id="agency">
		<div class="wrapper">
			<div class="avatar--details--container">
				<figure class="avatar">
					<img
						:alt="`${firstName} ${lastName}`"
						:src="`${app_file_base_path}/${photo}`"
					/>
				</figure>
				<div>
					<div class="agency-details">
						<div class="agency-details-label">
							{{ $t("offer.partner.text_1") }}
						</div>
						<div class="agency-details-name">
							{{ agencyName }}
						</div>
					</div>
					<div class="rating-summary">
						<a href="#reviews"
							class="review-link"
						>
							{{
								$tc(
									"page.my_trips.offer.agency.reviews",
									reviews,
									{ 0: $t(distinction) }
								)
							}}
						</a>
						<div class="rating-container">
							<Rating
								:rating="rating"
								:is-static="true"
								:loading="loading"
								:size="ratingSize"
							/>
							<span class="total-rating" v-if="rating">
								{{ rating }} / 5 {{ $t("reviews.stars") }}
							</span>
						</div>
					</div>
				</div>
			</div>
		</div>
	</section>
</template>
<script>
import Rating from "@/components/review/Rating";

export default {
	name: "AgencyBlock",
	data() {
		return {
			ratingSize: "md",
		};
	},
	components: {
		Rating,
	},
	props: {
		agencyName: {
			type: String,
			default: "Agency name",
		},
		firstName: {
			type: String,
			default: "First name",
		},
		lastName: {
			type: String,
			default: "Last name",
		},
		photo: {
			type: String,
		},
		loading: {
			type: Boolean,
			default: true,
		},
		reviews: {
			type: Number,
			default: 0,
		},
		rating: {
			type: Number,
			default: 0,
		},
	},
	computed: {
		app_file_base_path() {
			return process.env.VUE_APP_FILE_BASE_PATH;
		},
		distinction() {
			if (this.rating >= 4.25) return "reviews.distinction.excellent";
			if (this.rating >= 3.75 && this.rating < 4.25)
				return "reviews.distinction.good";
			if (this.rating >= 3.25 && this.rating < 3.75)
				return "reviews.distinction.average";
			if (this.rating >= 2.75 && this.rating < 3.25)
				return "reviews.distinction.poor";
			if (this.rating >= 2.25 && this.rating < 2.75)
				return "reviews.distinction.bad";
			if (this.rating >= 1.75 && this.rating < 2.25)
				return "reviews.distinction.miserable";
			return "reviews.distinction.no_reviews";
		},
	},
	methods: {
		elemViewportReset() {
			//Reset sheet position
			if (window.innerWidth >= 680) {
				this.ratingSize = "md";
			}

			if (window.innerWidth <= 681) {
				this.ratingSize = "xs";
			}
		},
	},
	async created() {
		window.addEventListener("resize", this.elemViewportReset);
	},
	destroyed() {
		window.removeEventListener("resize", this.elemViewportReset);
	},
	mounted() {
		this.elemViewportReset();
	},
};
</script>
<style scoped>
.section {
	@apply px-4 py-6;
}
.wrapper {
	@apply relative transform -translate-x-1/2 max-w-screen-sm left-1/2;
}

.avatar--details--container {
	@apply flex items-start w-full gap-2;
}
.rating-container {
	@apply flex items-center gap-0.5 mt-2;
}
.total-rating {
	@apply text-black-base font-semibold sm:text-lg;
	@apply inline-block ml-3;
}

.avatar {
	@apply relative min-w-20 h-20 sm:min-w-24 sm:h-24 overflow-hidden rounded-full;
}

.avatar img {
	@apply absolute object-cover w-full h-full;
}

.agency-details-label {
	@apply font-sans text-sm font-medium leading-tight text-black-lighter;
}

.agency-details-name {
	@apply font-sans font-semibold text-lg leading-tight text-black-base;
}

.review-link {
	@apply text-sm font-medium text-black-lighter;
	@apply underline mt-2 inline-block;
}
</style>